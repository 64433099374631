* {
  box-sizing: border-box;
}

body {
  background: $body-bg-color;
}

html {
  color: $text-color;
  font-family: $font-regular;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  height: auto;
  margin: 0;
  min-height: 100vh;
  padding: 0;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
input,
textarea,
html {
  font-family: $font-regular;
  font-weight: normal;
}

hr {
  border: 0;
  border-bottom: 1px solid #dedede;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

button,
input {
  appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  color: inherit;
  -webkit-font-smoothing: inherit;
  line-height: normal;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 0;
  width: auto;

  &:-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &:focus {
    outline: none;
  }

  &[disabled] {
    background: #eaeaea;
    color: #a8a8a8;
  }
}

button {
  font-family: $font-demi;
}

.button {
  margin: 0;

  &.ghost {
    border: .125rem solid $black;
    color: $black;
  }

  &.blue-ghost {
    border: .125rem solid $primary-color;
    color: $primary-color;
  }

  &.gray {
    background-color: #f4f4f4;
    color: #a3a3a3;
    font-family: $font-demi;
    font-size: 1rem;
  }

  &.red {
    background: #f44336;
    color: $white;
  }

  &[disabled] {
    background: #eaeaea;
    color: #a8a8a8;

    &.ghost,
    &.blue-ghost {
      border: 1.5px solid #eaeaea;
    }
  }
}

sup {
  color: #686868;
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: $wrapper;
  padding: 0 1rem;
  position: relative;
}

.error-text {
  color: $priority-color;
}

.lots-list {
  padding-bottom: 4rem;
}

.linear-progress-container {
  height: .25rem;
}

.list {
  display: grid;
  gap: .5rem;
  grid-template-columns: 1fr;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    background-color: #fff;
    border-radius: .25rem;
    padding: .25rem 0;

    &.selected {
      background-color: rgba(#2196f3, .11);
      box-shadow: 0 0 0 .125rem #2196f3 inset;
    }
  }
}

.no-show {
  align-items: center;
  display: flex;
  flex-direction: column;

  img {
    margin-top: 6.625rem;
  }

  p {
    color: #9d9d9d;
    font-family: $font-regular;
    font-size: 1.375rem;
  }
}

.warning-icon {
  --rmd-icon-color: #f44336;
}

.button-text {
  display: none;

  @include min-screen(calc(#{$sm} + 1px)) {
    display: inline-block;
  }
}

.button-icon {
  display: inline-block;

  @include min-screen(calc(#{$sm} + 1px)) {
    display: none;
  }
}
