@import '../../scss/imports';
@import '~@react-md/button/dist/mixins';
@import '~@react-md/icon/dist/mixins';

.filter-chip-container {
  margin: 0 .25rem;
  min-height: 2rem;
  position: relative;
  text-align: start;

  &:first-child {
    margin-left: 0;
  }
}

.filter-chip-action-container {
  align-items: center;
  background-color: #fff;
  bottom: 0;
  display: flex;
  height: 3.75rem;
  justify-content: flex-end;
  position: sticky;
  z-index: 10;
}

.filter-chip-apply-button {
  background-color: $primary-color;
  color: $white;
  width: 100%;
}

.filter-chip-apply-button:disabled {
  background-color: $disabled-color;
  color: lighten($disabled-color, 20%);
}

.filter-chip {
  cursor: pointer;
  position: relative;

  .filter-chip-header {
    align-items: center;
    background-color: #e0e0e0;
    border-radius: 1.625rem;
    color: $text-color;
    display: flex;
    font-family: $font-demi;
    font-size: .875rem;
    height: 2rem;
    justify-content: space-between;
    padding: 0 .375rem 0 1rem;
  }

  .chip-icon {
    .rmd-icon--font {
      color: inherit;
    }
  }

  .chip-icon {
    color: inherit;
  }

  .chip-remove-button {
    @include rmd-button-theme-update-var(icon-size, 1.25rem);
    color: $secondary-text-color;
  }

  .chip-close-icon {
    @include rmd-button-theme-update-var(icon-size, 1.5rem);
    color: $white;
  }

  .chip-label {
    font-family: $font-regular;
    height: 2rem;
    line-height: 2rem;
    margin-right: .3125rem;
    text-transform: capitalize;
    white-space: nowrap;
  }

  &.removable {
    .filter-chip-header {
      background-color: $primary-color;
      color: #fff;
    }
  }

  .rmd-text-field-addon:last-child {
    right: 0;
  }

  &.expanded {
    animation: open 300ms cubic-bezier(.29, .76, .58, .89);
    box-shadow: $box-shadow;
    position: absolute;
    text-align: start;
    top: 0;
    width: 21.875rem;
    z-index: 9;

    @include max-screen($sm) {
      animation: open-small 300ms cubic-bezier(.29, .76, .58, .89);
      left: 50% !important;
      max-width: calc(100vw - 1rem);
      position: fixed;
      top: 4.5rem;
      transform: translateX(-50%);
    }

    .filter-chip-header {
      background-color: $primary-color;
      border-radius: $border-radius $border-radius 0 0;
      color: #fff;
      height: 3.75rem;
      padding: 0 1rem;
    }

    .chip-label {
      font-family: $font-demi;
    }

    .filter-chip-dropdown {
      background-color: #fff;
      border-radius: 0 0 $border-radius $border-radius;
      box-shadow: $box-shadow;
      left: 0;
      max-height: 65vh;
      overflow-y: auto;
      padding: 1rem;
      padding-bottom: 0;
      position: absolute;
      top: 3.75rem;
      width: 100%;

      .chip-list {
        background-color: #fff;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          cursor: pointer;
        }
      }
    }
  }

  &.expand-left {
    right: 0;
  }

  &.expand-right {
    left: 0;
  }

  .list-container {
    max-height: 12.5rem;
    overflow-y: auto;
  }

  .filter-chip-search-input label {
    color: #000;

    &.rmd-floating-label--active {
      font-family: $font-demi;
      font-size: .875rem;
    }
  }
}

@keyframes open {
  0% {
    opacity: 0;
    transform: scale(0);

    @include max-screen($sm) {
      transform: scale(0) translateX(-50%);
    }
  }

  100% {
    opacity: 1;
    transform: scale(1);

    @include max-screen($sm) {
      transform: scale(1) translateX(-50%);
    }
  }
}

@keyframes open-small {
  0% {
    opacity: 0;
    transform: scale(0) translateX(-50%);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateX(-50%);
  }
}
