.ab-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;

  @include min-screen($ipad) {
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
}

.ab-middle {
  flex-grow: 1;
}

.ab-search {
  min-width: 100%;

  @include min-screen($ipad) {
    min-width: 16rem;
  }
}

.ab-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include min-screen($ipad) {
    gap: 1rem;
    justify-content: flex-end;
  }
}
