/* APP COLORS */
$black: #000;
$white: #fff;
$primary-color: #2196f3;
$secondary-color: #f44336;
$priority-color: #f11a1a;
$label-color: #878787;
$disabled-color: #c3c3c3;
$text-color: #121212;
$secondary-text-color: rgba($black, .6);
$body-bg-color: #fafafa;
$header-background-color: #fff;
$header-action-button-color: $white;
$light-text-color: rgba($white, .8);

/* TEXTURES */
$border: 1px solid $secondary-text-color;
$border-radius: .25rem;
$box-shadow: 0 .125rem .25rem rgba($black, .2);
$md-box-shadow: 0 .125rem .125rem 0 rgba(0, 0, 0, .14), 0 .0625rem .3125rem 0 rgba(0, 0, 0, .12), 0 .1875rem .0625rem -.125rem rgba(0, 0, 0, .2);

/* FONTS */
$font-path: '../assets/fonts';
$font-light: 'Avenir Next W01 Light', Arial, sans-serif;
$font-regular: 'AvenirNextLTW01-Regular', Arial, sans-serif;
$font-bold: 'Avenir Next W1G', Arial, sans-serif;
$font-numbers: 'Avenir Next Cyr W00 Regular', Arial, sans-serif;
$font-demi: 'Avenir Next LT W01 Demi', Arial, sans-serif;
$font-ultra-light: 'Avenir Next TW01 UltraLight', Arial, sans-serif;

$custom-typography-styles: (
  letter-spacing: normal
);

/* SCREEN SIZES */
$xxs: 25em; // 400px
$xs: 30em; // 480px
$sm: 37.5em; // 600px
$md: 60em; // 960px
$lg: 80em; // 1280px
$xl: 90em; // 1440px
$ipad: 48em; //768px
$landscape: 64em; //1024px

$wrapper: 75em; // 1200px


/* PH Overrides */
// $ph-font-path: 'assets/fonts';
$ph-header-background-color: #fff;
$ph-header-action-button-color: #a8a8a8 !important;
$ph-primary-color: $primary-color;
$ph-button-icon-size-small: 1.5rem;
