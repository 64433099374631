.md-dialog {
  max-width: 31.875rem;
}

.dialog-title {
  font-family: $font-demi;
  font-size: 1.375rem;
  margin: 0 0 1rem;
}

.dialog-title-with-icon {
  display: grid;
  gap: .75rem;
  grid-template-columns: 1.25rem 1fr;
}

.dialog-actions {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 1fr;
  padding: 0 1.5rem 1.5rem;

  @include min-screen(calc(#{$sm} + 1px)) {
    grid-template-columns: 1fr 1fr;
  }

  &.single {
    grid-template-columns: 1fr;
  }
}
