@use '../../scss/variables' as *;

.container {
  display: flex;
  gap: .5rem;
  margin: 1.5rem 0 1.25rem;
}

.backAction {
  --rmd-icon-color: rgba(0, 0, 0, .54);
  --rmd-button-icon-size: 2rem;
}

.label {
  font-family: $font-demi;
  font-size: 1.5rem;
  margin: 0;
}

.subText {
  font-size: 1.125rem;
  margin: .5rem 0 0;
}
