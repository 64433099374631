@use '../../scss/variables' as *;
@use '~@parkhub/parkhub-ui/dist/styles/media-queries' as *;

.footer {
  background-color: #f7f7f7;
  bottom: 0;
  box-shadow: 0 -.25rem .125rem rgba(6, 6, 6, .205);
  height: 4rem;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.actions {
  align-items: center;
  display: flex;
  gap: 1rem;
  height: 4rem;
}
