@use '../../scss/variables' as *;
@use '~@parkhub/parkhub-ui/dist/styles/media-queries' as *;

.table {
  border-collapse: separate;
  border-spacing: 0 .5rem;
  margin-bottom: 5.25rem;
  width: 100%;

  th,
  td {
    font-size: .75rem;
    text-align: center;

    @include min-screen($sm) {
      font-size: 1rem;
    }

    @include min-screen($md) {
      text-align: left;
    }

    &:nth-child(2) {
      text-align: left;
    }
  }
}

.thead {
  text-align: left;
  text-transform: capitalize;

  th {
    padding: .5rem 0;
  }
}

.thItem {
  align-items: center;
  display: flex;
}

.thIcon {
  margin: .875rem .5rem .625rem;
  padding: 0;
  width: .625rem;
}

.tooltipContainer {
  line-height: 1rem;
  margin-left: .5rem;
  width: 1.25rem;
}

.lots {
  display: flex;
  justify-content: flex-start;
}

tbody {
  tr {
    background: $white;
    border-radius: .25rem;
    cursor: pointer;
    height: 3rem;
  }

  td:first-child {
    border-bottom-left-radius: .25rem;
    border-left: 0;
    border-top-left-radius: .25rem;
  }

  td:last-child {
    border-bottom-right-radius: .25rem;
    border-right: 0;
    border-top-right-radius: .25rem;
    padding-right: 1.5rem;
  }

  td:nth-child(2) {
    font-family: $font-demi;
  }
}

.columns-4 {
  padding-bottom: 1.125rem;
  padding-top: 0;
  width: 23%;
}

.columns-4:nth-of-type(2) {
  width: 36%;
}

.columns-3 {
  width: 40%;
}

.checkbox {
  margin: .625rem .75rem;
}

.helpIcon {
  --rmd-icon-size: 1.25rem;
}

.selected {
  background: rgba($primary-color, .11);
  box-shadow: 0 0 0 .125rem #269cfc inset;

  i {
    --rmd-icon-color: #269cfc;
  }
}

.duplicate {
  background: rgba(255, 0, 0, .08);
}

.duplicateSelected {
  box-shadow: 0 0 0 .125rem #f00 inset;

  i {
    --rmd-icon-color: #f00;
  }
}

.tooltip {
  background: $black;
  border-radius: .1875rem;
  padding: 0 .5rem;
}

.tooltipList {
  align-self: center;
  color: $white;
  display: flex;
  flex-direction: column;
  font-size: .875rem;
  list-style: none;
  padding: 0 .25rem;
  width: 100%;

  li {
    padding: .125rem 0;
    width: 100%;
  }
}

.sortable {
  cursor: pointer;
}
