@use '../../scss/variables' as *;

.container {
  padding-bottom: 0;
}

.errors {
  list-style: none;
  margin: 0;
  padding: 0 0 1.25rem;
}

.errors > li {
  margin: 0 0 .5rem .625rem;
}

.errors > li::before {
  content: '- ';
  margin-left: -.625rem;
}

.button {
  width: 100%;
}
