@use '~@parkhub/parkhub-ui/dist/styles/media-queries' as *;
@use '../../scss/variables' as *;

.container {
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  box-sizing: border-box;
  color: $secondary-text-color;
  cursor: pointer;
  display: block;
  margin: 0 auto;
  max-height: 20.0625rem;
  max-width: 17.75rem;
  overflow: hidden;
  width: 100%;

  &:hover {
    background-color: darken($white, 10%);
    transition: background-color 200ms ease-in;
  }

  &.selected {
    background-color: rgba(#2196f3, .11);
    box-shadow: 0 0 0 3px #2196f3;
  }

  h5 {
    align-items: center;
    color: #101010;
    display: flex;
    font-size: .875rem;

    i {
      font-size: 1.25rem;
      margin-bottom: .125rem;
      margin-right: .3125rem;
    }
  }
}

.checkButton {
  border-color: rgba(0, 0, 0, .5);
  position: absolute;
  right: .75rem;
  top: .75rem;
  z-index: 1;

  &.background {
    color: #fff;
  }

  &.white {
    --rmd-icon-color: rgba(255, 255, 255, .65);
  }

  &.hidden {
    opacity: 0;
  }
}

.imageBackground {
  height: 10rem;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 0;
}

.text {
  height: 10rem;
}

.details {
  display: grid;
  gap: .5rem;
  grid-template-columns: 1fr;
  padding: 0;

  h5 {
    margin: 0;
  }
}

.green {
  --rmd-icon-color: #aed581;
}

.blue {
  --rmd-icon-color: #{$primary-color};
}

.imageContainer {
  position: relative;

  img {
    overflow: hidden;
    width: 100%;
  }
}

.title {
  margin: 0 auto .625rem;
  min-height: 2.25rem;
  position: relative;

  h5 {
    font-family: $font-demi;
    font-size: 1rem;
    margin: 0;
  }
}
